body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::file-selector-button {
  display: none;
}
/* .input {
  width: 100% !important;
} */
.react-datepicker-popper {
  left: -53px !important;
}
.sheduler-day-picker {
  margin: 10px;
}
.sheduler-main-container {
  display: flex;
}
.sheduler-container {
  display: flex;
}
.sheduler-button {
  margin: 4px;
}
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: none !important;
  font-family: "Arial", "Helvetica", sans-serif;
  line-height: 1.125em;
}
.form-check-input {
  margin-top: 15px !important;
}
.fc-popover {
  z-index: 100 !important;
}

.react-datepicker__input-container input {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  width: 100%;
}
